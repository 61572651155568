<template>
	<div class="update">
		<h2 class="qjc-fts-20 qjc-c-dark qjc-ftw-500">消息通知</h2>

		<div class="wrap">
			<div class="no-data" :style="{ minHeight: mainHeight + 'px' }" >
				暂无消息通知
			</div>
		</div>
	</div>
</template>

<script>
// import {   } from '@/utils/axios.js'

export default {
	name: 'mainNews',
	data() {
		return {
			mainHeight: '',
		};
	},

	mounted() {
		this.mainHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 250
		
	},
	components: {},
	watch: {
		
	},
	methods: {
		
	}
};
</script>

<style scoped>


</style>
<style lang="scss" scoped>
	.no-data{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
